import Vue from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import App from './App.vue';
import './assets/style/app.scss';

Vue.use(VueYandexMetrika, {
  id: 61170250,
  env: process.env.NODE_ENV,
});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
