<template>
  <div class="sponsors">
    <span class="sponsors-anchor"></span>

    <div class="sponsors__title">
      <h2 class="sponsors__h2">Наши партнеры</h2>
    </div>

    <div class="carousel-container">
      <carousel
      :perPage="3"
      :perPageCustom="[[0, 1], [576, 2], [992, 3], [1200, 4]]"
      :autoplay="false">
        <slide>
          <img src="../assets/images/sponsors/13.png" alt="Декатлон">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/09.png" alt="Кант">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/01.png" alt="Золотая долина">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/02.png" alt="Красное озеро">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/03.png" alt="Снежный">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/14.png" alt="Terror Snow">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/06.png" alt="Boardshop №1">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/04.png" alt="Toxopark">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/08.png" alt="New Star Camp">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/10.png" alt="Ruka">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/05.png" alt="Dirt Snow Service">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/07.png" alt="Dawinchi wake park">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/11.png" alt="IT-events">
        </slide>
        <slide>
          <img src="../assets/images/sponsors/12.png" alt="Wow life">
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style lang="scss" scoped>
  @import './../assets/style/variables.scss';

  .sponsors {
    width: 100%;
    padding-bottom: 5rem;
    background: $white;
    margin: 0 auto;
    &__title {
      padding: 10rem 0;
    }
    &__h2 {
      font-size: 3rem;
      text-align: center;
      font-weight: normal;
    }
  }

  .carousel-container {
    width: 1200px;
    margin: 0 auto;
  }

  .VueCarousel-slide {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1200px) {
    .carousel-container {
      width: 100%;
    }
  }
</style>

