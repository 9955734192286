<template>
  <div class="welcome-mat">
    <header class="welcome-mat__header">
      <a @click="scrollIntoView('about-anchor')" class="welcome-mat__link">О нас</a>
      <a @click="scrollIntoView('sponsors-anchor')" class="welcome-mat__link">Партнеры</a>
      <a @click="scrollIntoView('services-anchor')" class="welcome-mat__link">Услуги</a>
      <a @click="scrollIntoView('gallery-anchor')" class="welcome-mat__link">Галерея</a>
      <a @click="scrollIntoView('reviews-anchor')" class="welcome-mat__link">Отзывы</a>
      <a @click="scrollIntoView('contacts-anchor')" class="welcome-mat__link">Контакты</a>
    </header>

    <header class="welcome-mat__header_mobile" :class="{'welcome-mat__header_mobile_active': isAsideActive}">
      <div
      class="welcome-mat__burger"
      @click="
      isBurgerActive = !isBurgerActive;
      isAsideActive = !isAsideActive
      ">
        <span class="welcome-mat__span" :class="{'rotate-top': isBurgerActive}"></span>
        <span class="welcome-mat__span" :class="{'transparent': isBurgerActive}"></span>
        <span class="welcome-mat__span" :class="{'rotate-bottom': isBurgerActive}"></span>
      </div>
    </header>

    <transition name="slide">
      <aside class="welcome-mat__aside" v-show="isAsideActive">
        <a @click="scrollIntoView('about')" class="welcome-mat__link">О нас</a>
        <a @click="scrollIntoView('services')" class="welcome-mat__link">Услуги</a>
        <a @click="scrollIntoView('gallery-anchor')" class="welcome-mat__link">Галерея</a>
        <a @click="scrollIntoView('contacts')" class="welcome-mat__link">Контакты</a>
        <a @click="scrollIntoView('sponsors')" class="welcome-mat__link">Партнеры</a>
      </aside>
    </transition>

    <video class="welcome-mat__video" poster="../assets/images/welcome/poster.jpg" autoplay loop muted>
      <source src="../assets/video/background-video.mp4" type='video/mp4'>
      <img src="../assets/images/welcome/poster.jpg" alt="Ваш браузер не поддерживает video">
    </video>

    <div class="welcome-mat__title">
      <img src="../assets/images/welcome/logo.png" alt="Логотип" class="welcome-mat__logo">
    </div>

    <div class="welcome-mat__arrow-container">
      <img src="../assets/images/welcome/arrow.svg" class="welcome-mat__arrow" @click="scrollIntoView('about')">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBurgerActive: false,
      isAsideActive: false,
    };
  },

  methods: {
    scrollIntoView(className) {
      document.getElementsByClassName(className)[0]
        .scrollIntoView({
          behavior: 'smooth',
        });
    },
  },
};
</script>

<style scoped lang="scss">
  @import './../assets/style/variables.scss';

  .welcome-mat {
    width: 100%;
    height: 100vh;
    &__header {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1300px;
      height: 50px;
      margin-top: 4rem;
      &_mobile {
        display: none;
      }
    }
    &__aside {
      display: none;
    }
    &__logo {
      display: block;
      width: 450px;
      height: auto;
      margin-bottom: 2rem;
    }
    &__link {
      margin: 0 2rem;
      padding: 1rem;
      font-size: 1.7rem;
      text-decoration: none;
      color: $white;
      border-bottom: 3px solid transparent;
      transition: .4s;
      cursor: pointer;
      &:hover {
        border-bottom: 3px solid $white;
      }
    }
    &__video,
    &__alternative {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100vh;
      filter: brightness(35%);
      z-index: -50;
    }
    &__alternative {
      object-fit: fill;
    }
    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      color: $white;
    }
    &__arrow-container {
      position: absolute;
      left: 50%;
      bottom: 15px;
      transform: translate(-50%, 0) rotate(90deg);
      cursor: pointer;
    }
    &__arrow {
      width: 50px;
      height: 50px;
      animation: shake 1.5s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(1px);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(1px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(1px);
    }
  }

  @media (max-width: 1200px) {
    .welcome-mat {
      &__header {
        width: 992px;
      }
    }
  }

  @media (max-width: 992px) {
    .welcome-mat {
      &__header {
        width: 768px;
      }
    }
  }

  @media (max-width: 768px) {
    .welcome-mat {
      &__header {
        display: none;
        &_mobile {
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 9999;
          width: 100%;
          height: 80px;
          background: $white;
          &_active {
            padding-left: 280px;
          }
        }
      }
      &__burger {
        position: fixed;
        margin: 2.5rem;
        cursor: pointer;
      }
      &__span {
        display: block;
        background: $black;
        height: 4px;
        width: 40px;
        position: absolute;
        transition: all 0.5s ease;
        &:nth-child(1){
          top: 0px;
        }
        &:nth-child(2){
          top: 12px;
        }
        &:nth-child(3){
          top: 24px;
        }
      }
      &__aside {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        width: 280px;
        height: 100%;
        background: $white;
      }
      &__link {
        margin: 1.8rem 3rem;
        color: $black;
      }
    }

    .transparent {
      opacity:0;
    }

    .rotate-top {
      transform:rotatez(45deg);
      transform-origin:0%;
      left:2px
    }

    .rotate-bottom{
      transform:rotatez(-45deg);
      transform-origin: 15%;
    }

    .slide-leave-active,
    .slide-enter-active {
      transition: 1s;
    }
    .slide-enter {
      transform: translate(-100%, 0);
    }
    .slide-leave-to {
      transform: translate(-100%, 0);
    }
  }

  @media (max-width: 576px) {
    .welcome-mat {
      &__logo {
        width: 350px;
      }
    }
  }
</style>
