<template>
  <div class="app-container" id="app">
    <welcome-mat-component></welcome-mat-component>
    <about-us-component></about-us-component>
    <sponsors-component></sponsors-component>
    <services-component></services-component>
    <gallery-component></gallery-component>
    <reviews-component></reviews-component>
    <contacts-component></contacts-component>
    <stats-component></stats-component>
    <footer-component></footer-component>

    <transition name="fade">
      <div class="app-container__join" v-show="isPhoneShown">
        <a href="https://vk.com/im?media=&sel=-88867" target="_blank">
          <img src="./assets/images/mail.png" alt="Телефон" class="app-container__phone">
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import WelcomeMatComponent from './components/WelcomeMatComponent.vue';
import AboutUsComponent from './components/AboutUsComponent.vue';
import ServicesComponent from './components/ServicesComponent.vue';
import GalleryComponent from './components/GalleryComponent.vue';
import ContactsComponent from './components/ContactsComponent.vue';
import SponsorsComponent from './components/SponsorsComponent.vue';
import ReviewsComponent from './components/ReviewsComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import StatsComponent from './components/StatsComponent.vue';

export default {
  name: 'app',
  components: {
    WelcomeMatComponent,
    AboutUsComponent,
    ServicesComponent,
    GalleryComponent,
    ContactsComponent,
    SponsorsComponent,
    ReviewsComponent,
    FooterComponent,
    StatsComponent,
  },

  data() {
    return {
      isPhoneShown: false,
    };
  },

  mounted() {
    this.showPhone();
  },

  methods: {
    showPhone() {
      setTimeout(() => {
        this.isPhoneShown = true;
      }, 10000);
    },
  },

};
</script>

<style lang="scss">
  @import './assets/style/variables.scss';

  .app-container{
    &__join {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      right: 2rem;
      bottom: 2rem;
      width: 75px;
      height: 75px;
      background: $white;
      border-radius: 50%;
      animation: shake 2s;
      animation-iteration-count: infinite;
      cursor: pointer;
    }
    &__phone {
      width: 35px;
      height: auto;
    }
  }

  @keyframes shake {
    0% {
      transform: translateY(1px);
    }
    25% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(1px);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(1px);
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>

