<template>
  <div class="gallery">
    <span class="gallery-anchor"></span>

    <div class="gallery__title">
      <h2 class="gallery__h2">Галерея</h2>
    </div>

    <div class="gallery__images-container">
      <div
      class="col-3 gallery__overlay-container"
      v-for="(image, i) of galleryData.slice(0, 8)"
      v-bind:key="i">
        <img :src="require(`../assets/images/gallery/${image.id}.jpg`)" class="gallery__image">
        <div class="gallery__overlay">
          <p class="gallery__p">{{ image.description }}</p>
        </div>
      </div>
    </div>

    <transition name="slide">
      <div class="gallery__hidden-container" v-if="showAll">
        <div
        class="col-3 gallery__overlay-container"
        v-for="(image, i) of galleryData.slice(8, galleryData.length)"
        v-bind:key="i">
          <img :src="require(`../assets/images/gallery/${image.id}.jpg`)" class="gallery__image">
          <div class="gallery__overlay">
            <p class="gallery__p">{{ image.description }}</p>
          </div>
        </div>
      </div>
    </transition>

    <div class="gallery__link">
      <a v-on:click="showAll = !showAll" class="gallery__a">{{ this.showAll ? 'Скрыть' : 'Показать все' }}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      galleryData: [],
      showAll: false,
      descriptions: [
        'Выезд на 140 человек',
        'Раздача подарков',
        'New Star Camp 2017',
        'Bonus session',
        'Madness king',
        'Профессиональный экстрим-фоторграф',
        'Чемпион России по сноуборду',
        'Селфи на память обязательны!',
        'Тренировочный процесс',
        'Le Grand Finale',
        'Победители контеста в Финляндии',
        'Обучение по уходу за скользяком от Моби',
        'RUKA 2017',
        'Le Grand Finale',
        'RUKA 2017',
        'Победитель конкурса',
        'Madness king',
        'Тренировка в парке',
        'Sousage Party',
        'Поддержка друзей всегда важна',
        'Розыгрыш призов',
        'Выезд DOUBLE в DaWinchi',
        'Летом осваиваем вейкборд',
        'RUKA 2018',
      ],
    };
  },

  mounted() {
    this.addObjectOfImages();
    this.shuffleImages();
  },

  methods: {
    addObjectOfImages() {
      for (let i = 1; i < 25; i++) {
        this.galleryData.push({
          id: `0${i}`.slice(-2),
          description: this.descriptions[i - 1],
        });
      }
    },

    shuffleImages() {
      const arr = this.galleryData;
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './../assets/style/variables.scss';

  .gallery {
    width: 100%;
    padding-bottom: 5rem;
    background: $white;
    &__title {
      padding: 10rem 0;
    }
    &__h2 {
      font-size: 3rem;
      text-align: center;
      font-weight: normal;
    }
    &__images-container,
    &__hidden-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    &__image {
      width: 100%;
      height: 100%;
      transition: .3s ease;
    }
    &__overlay-container {
      position: relative;
      overflow: hidden;
      &:hover .gallery__image {
        transform: scale(1.5);
        filter: brightness(50%);
      }
      &:hover .gallery__p {
        opacity: 1;
      }
    }
    &__overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 65%;
    }
    &__p {
      opacity: 0;
      font-size: 1.6rem;
      text-align: center;
      line-height: 2.5rem;
      text-transform: uppercase;
      color: $white;
      transition: opacity .5s;
      cursor: default;
    }
    &__link {
      padding-top: 4rem;
      text-align: center;
    }
    &__a {
      text-decoration: none;
      color: $grey-text;
      border-bottom: 1px dashed $grey-text;
      transition: .2s;
      cursor: pointer;
      &:hover {
        color: $blue;
        border-bottom: 1px dashed transparent;
      }
    }
  }

  .col-3 {
    width: 25%;
  }

  .slide-enter-active, .slide-leave-active {
    transition: all .3s ease;
  }

  .slide-enter, .slide-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  @media (max-width: 1200px) {
    .col-3 {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    .col-3 {
      width: 100%;
    }
  }
</style>

